export const msalConfig = {
    auth: {
      clientId: "11742a49-561d-4b4b-99fe-b53ba92fa499",
      authority: "https://login.microsoftonline.com/fad42abb-dfda-43f0-9120-b18e6e86169d", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
      redirectUri: "https://ledger.hosting.gss.gov.uk"
    },
    cache: {
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
  };
  
  // Add scopes here for ID token to be used at Microsoft identity platform endpoints.
  export const loginRequest = {
   scopes: ["api://023036c9-4f3c-4a08-ba86-9d5bd0058766/Files.Read"]
  };
  
  // Add the endpoints here for Microsoft Graph API services you'd like to use.

